import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PageContent from "../../page-views/induction-bending/pipe-bends"
import BgImage from "../../assets/images/2017/08/petrochemical-refinery.jpg"

const PipeBendsPage = () => {
  const title = "Pipe Bends"
  const content = "2D, 3D and 5D pipe bends and everything in between"

  return (
    <Layout>
      <Hero title={title} content={content} bgImage={BgImage} />
      <PageContent />
    </Layout>
  )
}

export default PipeBendsPage
